import React from 'react'
import styled from 'styled-components'

const encode = (data) => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

export default class Contact extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: "",
      email: "",
      message: "",
      isSuccess: false,
      isError: false
    }
  }

  handleSubmit = e => {
    const { isFalse, isError, ...rest } = this.state

    if (!rest.name || !rest.email || !rest.message) {
      this.setState({ isError: true })
      return
    }

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...rest })
    })
    .then(() => {
      this.setState({ isSuccess: true })
    })
    .catch(error => alert(error))

    e.preventDefault()
  }

  handleChange = e => this.setState({
    [e.target.name]: e.target.value,
    isSuccess: false
  })

  emptyForm = e => this.setState({
    name: "",
    email: "",
    message: "",
    isSuccess: false
  })

  hideOverlay = e => this.setState({
    isError: false
  })

  render () {
    const { name, email, message, isSuccess, isError } = this.state
    return (
      <Form onSubmit={this.handleSubmit}>
        <p>Feel free to drop us a quick line below</p>
        <input type="hidden" name="form-name" value="contact" />
        <p hidden>
          <Label>
            Don’t fill this out: <input name="bot-field" />
          </Label>
        </p>
        <p>
          <Label>
            Your name:<br />
            <Input type="text" name="name" value={name} onChange={this.handleChange} />
          </Label>
        </p>
        <p>
          <Label>
            Your email:<br />
            <Input type="email" name="email" value={email} onChange={this.handleChange} />
          </Label>
        </p>
        <p>
          <Label>
            Message:<br />
            <Textarea name="message" value={message} onChange={this.handleChange} />
          </Label>
        </p>
        <p>
          <Button type="submit" onClick={this.handleSubmit}>Send</Button>
        </p>
        {isSuccess && <Overlay onClick={this.emptyForm}>
          <Message>
            <p>Thank you for your message. We will respond as soon as we get a chance, typically within the next day or two.</p>
          </Message>
        </Overlay>}
        {isError && <Overlay onClick={this.hideOverlay}>
          <Message>
            <p>Please be so kind as to complete all fields, including a valid email address, so we can respond to your message. Thank you!</p>
          </Message>
        </Overlay>}
        <p>We are looking forward to talking with you!</p>
      </Form>
    )
  }
}

const Form = styled.div`
  @media screen and (min-width: 960px) {
    padding: 2rem;
    border: 1px dashed rgba(200, 200, 200, 0.5)
  }
`

const Input = styled.input`
  max-width: 100%;
  width: 400px;
  line-height: 44px;
  padding: 0 16px;
  border: 1px solid #ccc;

  &:-webkit-autofill {
    background: inherit;
    font: inherit;
    line-height: 44px;
    padding: 0 16px;
    border: 1px solid #ccc;
  }
`

const Label = styled.label`

`

const Textarea = styled.textarea`
  max-width: 100%;
  width: 400px;
  height: 200px;
  padding: 16px;
  border: 1px solid #ccc;
`

const Button = styled.button`
  background: #4a80d4;
  color: #fff;
  border: 1px solid #fff;
  padding: 0 16px;
  line-height: 44px;
  cursor: pointer;

  &:hover {
    background: #fff;
    color: #4a80d4;
    border: 1px solid #4a80d4;
  }
`

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

// const Title = styled.p`
//   ${props => props.red ? `
//     color: #de5252;
//   ` : `
//     color: #3ac05f;
//   `}
//   font-size: 24px;
//   font-weight: 700;
// `

const Message = styled.div`
  background: #fff;
  max-width: 400px;
  padding: 32px 24px;
`